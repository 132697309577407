.contact {
    background-color: #333;
    color: #fff;
    padding: 2rem;
    text-align: center;
  }
  
  .contact__title {
    font-size: 2rem;
    margin: 0;
  }
  
  .contact__text {
    font-size: 1rem;
    margin-top: 1.5rem;
  }
  
  .contact__form {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Add additional styling for form fields */
  
  