.footer {
    background-color: #f2f2f2;
    color: #333;
    padding: 1rem;
    text-align: center;
  }
  
  .footer__text {
    margin: 0;
  }
  