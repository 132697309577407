.about {
    background-color: #333;
    color: #fff;
    padding: 2rem;
    text-align: center;
  }
  
  .about__title {
    font-size: 2rem;
    margin: 0;
  }
  
  .about__text {
    font-size: 1rem;
    margin-top: 1.5rem;
  }
  
  