.portfolio-page {
  gap: 20px;
  justify-content: center;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.project-item {
  width: 400px;
  height: 400px;
  margin: 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.project-item, .project-item a {
    width: 400px;
    height: 400px;
    margin: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-decoration: none; /* Removes the underline from links */
    color: inherit; /* Ensures the link text has the correct color */
}

.project-item:hover {
  position: relative;
}

.project-item:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.project-item:hover .project-title {
  display: none;
}

.project-item:hover .project-description {
  display: block;
}

.project-content {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.project-title {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}

.project-description {
  font-size: 40px;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  display: none;
  z-index: 2
}

.project-image {
  background-size: contain;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  width: 400px;
}
