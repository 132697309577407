.home {
    background-color: #fff;
    color: #333;
    padding: 2rem;
    text-align: center;
  }
  
  .home__title {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .home__subtitle {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 1rem;
  }
  
  .home__button {
    background-color: #333;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    font-size: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;
  }
  
  .home__button:hover {
    background-color: #555;
  }
  
  